import React from 'react';
import { Link } from 'gatsby';
import Profeel from '../images/footer/logo.png';
import Label from '../images/footer/cee.png';
import AQClogo from '../images/AQC.png';

const Footer = () => (
  <footer
    className="-md:flex-row -lg:px-16 sm:py-6 py-7 absolute bottom-0 z-50 flex flex-col items-center justify-between w-full max-w-full px-3 text-base"
    style={{ background: 'linear-gradient(320.07deg, #2F8975 24.52%, #0078A3 72.78%)' }}
  >
    <div className="-lg:w-1/2 md:w-3/4 md:mb-4 flex flex-row justify-between w-1/3">
      <div className="sm:w-1/4 h-3/4 w-2/5">
        <a href="https://programmeprofeel.fr" target="_blank" rel="noreferrer">
          <img className="md:mx-8 sm:mx-0 w-full h-full mr-4" src={Profeel} alt="profeelLogo" />
        </a>
      </div>
      <div className="sm:w-1/4 h-4/6 w-1/3">
        <img className="md:mx-8 sm:mx-0 w-full h-full mr-4" src={Label} alt="CEELabellogo" />
      </div>
    </div>
    <div className="-md:w-3/4 sm:ml-2 md:flex-col flex flex-row flex-no-wrap items-center justify-end w-full text-white">
      <div className="md:mx-2 md:w-full -md:mr-8 flex flex-col items-end justify-between w-3/4">
        <div className="nav nav-1 md:m-auto md:justify-between flex flex-row justify-end font-bold">
          <Link to="/infos/FAQ/">FAQ</Link>
          <Link
            to="/contact/"
            className="text-center"
            style={{ marginLeft: '6.65rem' }}
          >Contact</Link>
        </div>
        <div className="nav nav-2 -xxl:w-3/4 flex flex-row justify-between w-full font-medium text-center">
          <Link to="/infos/LegalMentions/" className="mx-2 text-center">
            Mentions légales
          </Link>
          <Link to="/infos/CGU/" className="mx-2 text-center">
            CGU
          </Link>
          <Link to="/infos/Politique/" className="md:mx-2">
            Politique de confidentialité
          </Link>
        </div>
      </div>
      <a href="https://qualiteconstruction.com/" target="_blank" rel="noreferrer" className="md:mt-5">
        <img src={AQClogo} className="md:h-8" alt="AQClogo" />
      </a>
    </div>
  </footer>
);

export default Footer;
