import React from 'react';
import Header from './header';
import Footer from './footer';
import '../css/layout.css';

const Layout = ({ children }) => {
  return (
    <div
      style={{
        maxWidth: `100%`,
        position: 'relative',
        minHeight: 'inherit'
      }}
    >
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
