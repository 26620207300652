import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import AQClogo from '../images/AQC.png';
import styled from 'styled-components';
import titleLogo from '../images/header/titleLogo.svg';

const globalGradient = 'linear-gradient(320.07deg, #2F8975 24.52%, #0078A3 72.78%)';

const ConnexionButton = styled.button`
  border-radius: 50px;
  color: #ffff;
  background: ${globalGradient};
  padding: 8px 40px;
  outline: 0;
  margin-right: 1.5rem;
  @media (max-width: 767px) {
    padding: 5px 12px;
    font-size: 1rem;
    margin-right: unset;
  }
  @media (max-width: 450px) {
    font-size: 0.75rem;
  }
`;

const RegisterButton = styled(ConnexionButton)`
  background: none;
  border: 1px solid;
  border-color: rgb(32, 178, 170);
  color: rgb(32, 178, 170);
`;

const Header = () => (
  <header className="-lg:px-16 md:text-base sm:text-xs fixed top-0 z-50 flex flex-wrap items-center justify-between w-full max-w-full px-3 py-4 bg-white">
    <Link to="/">
      <img src={titleLogo} alt="checkRENO" />
    </Link>
    <nav className="md:mt-4 md:text-base xs:text-xs sm:w-full flex items-center justify-between flex-shrink w-auto text-xl">
      <Link className="headerItem / sm:mr-2 mr-6 text-gray-500 cursor-pointer" to="/download">
        Télécharger les fiches
      </Link>
      <a href={process.env.BO_CONNECTION_URL} target="_blank" rel="noreferrer">
        <ConnexionButton className="sm:mr-2">Connexion</ConnexionButton>
      </a>
      <a href={process.env.BO_INSCRIPTION_URL} target="_blank" rel="noreferrer">
        <RegisterButton>Inscription</RegisterButton>
      </a>
      <a href="https://qualiteconstruction.com/" target="_blank" rel="noreferrer">
        <img src={AQClogo} alt="AQC logo" className="md:h-8 sm:absolute sm:top-10 sm:right-4" />
      </a>
    </nav>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: `CHECK'RENO`,
};

export default Header;
